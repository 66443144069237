import { Location, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonButton, IonContent, IonIcon, ModalController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';
import { SubscriptionLike } from 'rxjs';
import { Image } from 'viamondo-core/data';

import { ImageDirective } from '../../directives/image.directive';

@Component({
  selector: 'app-image',
  templateUrl: './image.modal.html',
  styleUrls: ['./image.modal.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ImageDirective, IonContent, IonButton, IonIcon],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImageModal implements OnInit, AfterViewInit, OnDestroy {
  private locationSubscription: SubscriptionLike;

  @Input()
  index: number;

  @Input()
  images: Image[] = [];

  @ViewChild('swiper')
  swiperContainer: ElementRef | undefined;

  constructor(
    private modalController: ModalController,
    private location: Location
  ) {
    addIcons({ close });
  }

  ngOnInit(): void {
    this.location.go(`${this.location.path(false)}#image-modal`);
    this.locationSubscription = this.location.subscribe(() => this.modalController.dismiss());
  }

  ngAfterViewInit(): void {
    if (this.swiperContainer) {
      const swiperEl = this.swiperContainer.nativeElement;
      const swiperParams = {
        slidesPerView: 1,
        pagination: true,
        initialSlide: this.index
      };
      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
    }
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

  hide(): void {
    this.location.back();
  }
}
