import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular/standalone';
import { LocalStorageService } from 'viamondo-core/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionTokenGuard {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private platform: Platform
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.localStorageService.getToken()) {
      return true;
    } else {
      if (this.platform.is('hybrid') || this.platform.is('pwa')) {
        return this.router.createUrlTree(['session', 'login'], { queryParams: { return: state.url } });
      } else {
        return this.router.createUrlTree(['session']);
      }
    }
  }
}
