import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Image } from 'viamondo-core/data';

import { ImageDirective } from '../../directives/image.directive';
import { ShowImagesDirective } from '../../directives/show-images.directive';

@Component({
  selector: 'images-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, ImageDirective, ShowImagesDirective]
})
export class ListComponent {
  @Input()
  images: Image[] = [];

  @Input()
  hidePrimary = false;
}
