import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonButton, IonFabButton, IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { bookmark } from 'ionicons/icons';
import { Place, PlacesService, Trip, TripsService } from 'viamondo-core/data';

@Component({
  selector: 'app-bookmark-button',
  templateUrl: './bookmark-button.component.html',
  styleUrls: ['./bookmark-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, IonFabButton, IonIcon, IonButton]
})
export class BookmarkButtonComponent {
  @Input()
  item: Trip | Place;

  @Input()
  withText = false;

  constructor(
    private tripsService: TripsService,
    private placesService: PlacesService
  ) {
    addIcons({ bookmark });
  }

  toggle(): void {
    if (this.item instanceof Trip) {
      this.tripsService.toggleBookmark(this.item);
    } else {
      this.placesService.toggleBookmark(this.item);
    }
  }
}
