import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, zip } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { BackendService, UnpopulatedPlace } from '../../api';
import { GeocodingService } from '../../location';
import { GeoLocation } from '../models/geo-location';
import { MinimalPlace } from '../models/minimal-place';
import { Place } from '../models/place';
import { Tag } from '../models/tag';
import { Venue } from '../models/venue';

import { BasicService } from './basic.service';
import { BookmarksStateService } from './bookmarks-state.service';
import { FeedsStateService } from './feeds-state.service';
import { ImageService } from './image.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class PlacesService extends BasicService {
  private checkinPlace: Place | undefined;

  constructor(
    usersService: UsersService,
    backendService: BackendService,
    bookmarksStateService: BookmarksStateService,
    alertController: AlertController,
    private feedsStateService: FeedsStateService,
    private spinnerService: NgxSpinnerService,
    private geocodingService: GeocodingService,
    private imageService: ImageService
  ) {
    super(alertController, backendService, usersService, bookmarksStateService);
  }

  getPlacesByUserId(userId: string): Observable<MinimalPlace[]> {
    return this.backendService.getPlacesByUser(userId).pipe(map(placeTOs => placeTOs.map(p => MinimalPlace.fromBackend(p))));
  }

  getPlace(placeId: string): Observable<Place> {
    const backendCall = this.isSlug(placeId)
      ? this.backendService.getPlaceDetailsBySlug(placeId)
      : this.backendService.getPlaceDetails(placeId);
    return backendCall.pipe(
      map(placeTO => {
        const place = Place.fromBackend(placeTO);
        super.initialize(place);
        return place;
      })
    );
  }

  saveCheckin(place: Place): void {
    this.checkinPlace = place;
  }

  getCheckin(): Place | undefined {
    return this.checkinPlace;
  }

  clearCheckin(): void {
    this.checkinPlace = undefined;
  }

  save(place: Place): Observable<any> {
    this.spinnerService.show();
    const unpopulated = this.convertToUnpopulatedPlace(place);

    return zip(this.imageService.uploadImages('places', place.images), this.standardizeVenueInfo(place.venue)).pipe(
      map(([images, venue]) => {
        unpopulated.images = images;
        unpopulated.venue = venue;
      }),
      mergeMap(() => {
        if (place._id) {
          return this.backendService.savePlace(place._id, unpopulated);
        } else {
          return this.backendService.createPlace(unpopulated);
        }
      }),
      tap(() => this.feedsStateService.reload())
    );
  }

  delete(place: Place): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.alertController
      .create({
        header: 'Delete this check-in?',
        message: `Are you sure you want to delete this check-in? This action is not reversible.`,
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              subject.next(false);
              subject.complete();
            }
          },
          {
            text: 'Yes',
            handler: () => {
              this.spinnerService.show();
              this.backendService.deletePlace(place._id).subscribe(() => {
                subject.next(true);
                subject.complete();
              });
            }
          }
        ]
      })
      .then(alert => alert.present());

    return subject.asObservable().pipe(tap(() => this.feedsStateService.reload()));
  }

  private convertToUnpopulatedPlace(place: Place): UnpopulatedPlace {
    const user = this.usersService.getCurrentUser();

    const unpopulated: UnpopulatedPlace = {
      startDate: place.startDate,
      endDate: place.endDate ? place.endDate : place.startDate,
      comment: place.comment,
      rating: place.rating,
      images: [],
      participants: [user._id],
      privacy: place.privacy,
      venue: undefined,
      tags: this.convertTags(place.tags)
    };

    if (place.trip) {
      unpopulated.tripId = place.trip._id;
    }

    if (place.venue._id) {
      unpopulated.venueId = place.venue._id;
    }

    return unpopulated;
  }

  private standardizeVenueInfo(venue: Venue): Observable<Venue> {
    return this.geocodingService.reverse(venue.location.lat, venue.location.lng).pipe(
      map((location: GeoLocation) => {
        venue.location = location;
        return venue;
      })
    );
  }

  private convertTags(tags: Tag[]): Tag[] {
    return tags.filter(({ values }) => values.length > 0);
  }
}
