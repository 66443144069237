import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LeafletModule } from '@bluehalo/ngx-leaflet';

import { SingleMapConfig } from '../../models/single-map-config';
import { MapService } from '../../services/map.service';

let counter = 0;

@Component({
  selector: 'app-single-point-map',
  templateUrl: './single-point-map.component.html',
  styleUrls: ['./single-point-map.component.scss'],
  standalone: true,
  imports: [NgIf, LeafletModule, NgFor]
})
export class SinglePointMapComponent {
  id = `single-point-map-${counter++}`;
  mapConfig?: SingleMapConfig;

  @Input()
  set mapDataCreator(func: () => SingleMapConfig | undefined) {
    if (func) {
      this.mapConfig = func();
    }
  }

  constructor(private mapService: MapService) {}

  onMapReady(map: L.Map): void {
    setTimeout(() => {
      // Work-around to fix grey map
      this.mapService.getTileLayer().addTo(map);
      map.invalidateSize(false);
    }, 500);
  }
}
