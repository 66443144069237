import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { Interval, isWithinInterval, parseISO } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { BackendService, UnpopulatedTrip } from '../../api';
import { MinimalTrip } from '../models/minimal-trip';
import { Trip } from '../models/trip';

import { BasicService } from './basic.service';
import { BookmarksStateService } from './bookmarks-state.service';
import { FeedsStateService } from './feeds-state.service';
import { ImageService } from './image.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class TripsService extends BasicService {
  private currentTrip: MinimalTrip;

  constructor(
    usersService: UsersService,
    private imageService: ImageService,
    alertController: AlertController,
    private spinnerService: NgxSpinnerService,
    private feedsStateService: FeedsStateService,
    bookmarksStateService: BookmarksStateService,
    backendService: BackendService
  ) {
    super(alertController, backendService, usersService, bookmarksStateService);
  }

  getOwnTrips(): Observable<MinimalTrip[]> {
    return this.getTripsByUser(this.usersService.getCurrentUser()._id);
  }

  getTripsByUser(userId: string): Observable<MinimalTrip[]> {
    return this.backendService.getTripsByUser(userId).pipe(map(tripTOs => tripTOs.map(t => MinimalTrip.fromBackend(t))));
  }

  getTrip(tripId: string): Observable<Trip> {
    const backendCall = this.isSlug(tripId) ? this.backendService.getTripDetailsBySlug(tripId) : this.backendService.getTripDetails(tripId);
    return backendCall.pipe(
      map(tripTO => {
        const trip = Trip.fromBackend(tripTO);
        super.initialize(trip);
        return trip;
      })
    );
  }

  getCurrentTrip(): Observable<MinimalTrip> {
    if (this.isCurrentTripCurrent()) {
      return of(this.currentTrip);
    } else {
      return this.backendService.getCurrentTrip().pipe(
        map(tripTO => {
          if (tripTO) {
            return MinimalTrip.fromBackend(tripTO);
          } else {
            return undefined;
          }
        }),
        tap(trip => (this.currentTrip = trip))
      );
    }
  }

  private isCurrentTripCurrent(): boolean {
    if (!this.currentTrip) {
      return false;
    }
    const interval: Interval = { start: parseISO(this.currentTrip.startDate), end: parseISO(this.currentTrip.endDate) };
    return isWithinInterval(new Date(), interval);
  }

  save(trip: Trip): Observable<void> {
    this.spinnerService.show();
    const tripTO = this.convertToUnpopulatedTrip(trip);
    return this.imageService.uploadImages('trips', trip.images).pipe(
      map(images => (tripTO.images = images)),
      mergeMap(() => {
        if (trip._id) {
          return this.backendService.saveTrip(trip._id, tripTO);
        } else {
          return this.backendService.createTrip(tripTO);
        }
      }),
      tap(() => this.feedsStateService.reload())
    );
  }

  delete(trip: Trip): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.alertController
      .create({
        header: 'Delete this trip?',
        message: `Are you sure you want to delete this trip? This action is not
      reversible and will also delete all associated check-ins and routes.`,
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              subject.next(false);
              subject.complete();
            }
          },
          {
            text: 'Yes',
            handler: () => {
              this.spinnerService.show();
              this.backendService.deleteTrip(trip._id).subscribe(() => {
                subject.next(true);
                subject.complete();
              });
            }
          }
        ]
      })
      .then(alert => alert.present());

    return subject.asObservable().pipe(tap(() => this.feedsStateService.reload()));
  }

  private convertToUnpopulatedTrip(trip: Trip): UnpopulatedTrip {
    const user = this.usersService.getCurrentUser();

    const unpopulated: UnpopulatedTrip = {
      startDate: trip.startDate,
      endDate: trip.endDate,
      name: trip.name,
      description: trip.description,
      images: [],
      participants: [user._id],
      privacy: trip.privacy
    };

    return unpopulated;
  }
}
