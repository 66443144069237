import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonIcon,
  IonRouterLink,
  IonSearchbar,
  IonToolbar,
  PopoverController
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { bookmark, compass, logIn, notifications, person, personAdd, settings } from 'ionicons/icons';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsService } from 'viamondo-core/data';
import { SessionService } from 'viamondo-core/session.service';

import { NotificationsModal } from '../../users/modals/notifications/notifications.modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, AsyncPipe, IonRouterLink, IonToolbar, IonSearchbar, IonButtons, IonButton, IonIcon, IonBadge]
})
export class HeaderComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
  searchTerm: Observable<string>;
  notifications: Observable<number>;

  constructor(
    private popoverController: PopoverController,
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationsService: NotificationsService
  ) {
    addIcons({ notifications, person, compass, bookmark, settings, personAdd, logIn });
  }

  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.isLoggedIn();
    this.searchTerm = this.activatedRoute.queryParamMap.pipe(map(queryMap => queryMap.get('searchTerm') || ''));
    this.notifications = this.notificationsService.getUnreadChanges();
  }

  async showNotifications(event: any): Promise<void> {
    const modal = await this.popoverController.create({
      backdropDismiss: true,
      component: NotificationsModal,
      componentProps: {
        displayType: 'popover'
      },
      cssClass: 'notifications',
      event
    });
    return await modal.present();
  }

  onSearchInput(event: any): void {
    const searchTerm = event.detail.value;
    if (searchTerm) {
      const url = this.router.createUrlTree(['tabs', 'search'], {
        queryParams: { searchTerm }
      });
      this.router.navigateByUrl(url);
    }
  }
}
