import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActionSheetController, AlertController, IonIcon, ModalController, Platform } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { camera } from 'ionicons/icons';
import { Image, ImageService } from 'viamondo-core/data';

import { ImageDirective } from '../../directives/image.directive';
import { ImageModal } from '../../modals/image/image.modal';

@Component({
  selector: 'images-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ImageDirective, IonIcon]
})
export class ListInputComponent {
  @Input()
  images: Image[] = [];

  @Input()
  folder: string;

  imageIndexes = [0, 1, 2, 3, 4];

  constructor(
    private platform: Platform,
    private imageService: ImageService,
    private modalController: ModalController,
    private alertController: AlertController,
    private actionSheetCtrl: ActionSheetController
  ) {
    addIcons({ camera });
  }

  async addImage(index: number): Promise<void> {
    if (this.isBrowser()) {
      this.selectPhoto(index);
    } else {
      const buttons = [
        { text: 'Take photo', icon: 'camera', handler: () => this.takePhoto(index) },
        { text: 'Select from library', icon: 'images', handler: () => this.selectPhoto(index) }
      ];
      const actionSheet = await this.actionSheetCtrl.create({ buttons });
      await actionSheet.present();
    }
  }

  async changeImage(index: number): Promise<void> {
    if (this.isBrowser()) {
      const buttons = [
        { text: 'View photo', icon: 'eye', handler: () => this.viewPhoto(index) },
        { text: 'Make primary', icon: 'easel', handler: () => this.makePrimary(index) },
        { text: 'Replace with a new photo', icon: 'images', handler: () => this.selectPhoto(index) },
        { text: 'Remove photo', icon: 'trash', role: 'destructive', handler: () => this.deletePhoto(index) }
      ];
      const alert = await this.alertController.create({
        header: 'What would you like to do with this photo?',
        buttons
      });
      await alert.present();
    } else {
      const buttons = [
        { text: 'View photo', icon: 'eye', handler: () => this.viewPhoto(index) },
        { text: 'Make primary', icon: 'easel', handler: () => this.makePrimary(index) },
        { text: 'Take photo', icon: 'camera', handler: () => this.takePhoto(index) },
        { text: 'Select from library', icon: 'images', handler: () => this.selectPhoto(index) },
        { text: 'Remove photo', icon: 'trash', role: 'destructive', handler: () => this.deletePhoto(index) }
      ];
      const actionSheet = await this.actionSheetCtrl.create({ buttons });
      await actionSheet.present();
    }
  }

  private isBrowser(): boolean {
    return this.platform.is('pwa') || this.platform.is('mobileweb') || this.platform.is('desktop');
  }

  private async viewPhoto(index: number): Promise<void> {
    const imageModal = await this.modalController.create({
      component: ImageModal,
      componentProps: {
        index,
        images: this.images
      }
    });
    return await imageModal.present();
  }

  private makePrimary(index: number): void {
    const currentPrimary = this.findPrimaryIndex();
    if (currentPrimary !== null) {
      this.images[currentPrimary].primary = false;
    }
    this.images[index].primary = true;
  }

  private takePhoto(index?: number): void {
    this.imageService.takePhoto().subscribe(image => {
      if (index !== undefined && this.images[index]) {
        this.images[index] = image;
      } else {
        this.images.push(image);
      }
      this.findNewPrimary();
    });
  }

  private selectPhoto(index?: number): void {
    this.imageService.selectPhotos(this.determineLimit(index)).subscribe(images => {
      this.assignPhotos(images, index);
    });
  }

  private deletePhoto(index: number): void {
    this.images.splice(index, 1);
    this.findNewPrimary();
  }

  private determineLimit(index?: number): number {
    let limit = this.imageIndexes.length - this.images.length;
    if (index !== undefined && this.images[index]) {
      limit++;
    }
    return limit;
  }

  private assignPhotos(images: Image[], index?: number): void {
    if (index !== undefined && this.images[index] && images.length > 0) {
      this.images[index] = images[0];
      this.images.push(...images.slice(1));
    } else {
      this.images.push(...images);
    }
    this.findNewPrimary();
  }

  private findPrimaryIndex(): number {
    let currentPrimary = null;
    this.images.forEach((img, index) => {
      if (img.primary) {
        currentPrimary = index;
      }
    });
    return currentPrimary;
  }

  private findNewPrimary(): void {
    const currentPrimary = this.findPrimaryIndex();
    if (currentPrimary === null && this.images.length > 0) {
      this.images[0].primary = true;
    }
  }
}
