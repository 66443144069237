import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removehttp',
  standalone: true
})
export class RemoveHttpPipe implements PipeTransform {
  transform(text: string): string {
    if (text) {
      return text.replace('http://', '').replace('https://', '');
    } else {
      return text;
    }
  }
}
