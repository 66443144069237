import { NgFor } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'places-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [NgFor, IonIcon]
})
export class RatingComponent implements ControlValueAccessor {
  @Input()
  set readOnlyValue(value: number) {
    if (value !== undefined) {
      this.readOnly = true;
      this.rating = value;
    } else {
      this.readOnly = false;
    }
  }

  rating: number;
  ratingValues = [1, 2, 3, 4, 5];
  private readOnly: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: number): void => undefined;

  setRating(value: number): void {
    if (!this.readOnly) {
      this.rating = value;
      this.propagateChange(this.rating);
    }
  }

  writeValue(value: number): void {
    if (value !== undefined) {
      this.rating = value;
    }
  }

  registerOnChange(fn: () => any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}
}
