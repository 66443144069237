import { DatePipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonIcon, IonItem, IonLabel, IonSkeletonText, IonThumbnail } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { calendar, person } from 'ionicons/icons';
import { MinimalTrip } from 'viamondo-core/data';

import { ImageDirective } from '../../../images/directives/image.directive';

@Component({
  selector: 'trips-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, ImageDirective, DatePipe, IonItem, IonThumbnail, IonLabel, IonIcon, IonSkeletonText]
})
export class ItemComponent {
  @Input()
  trip: MinimalTrip;

  @Input()
  usage = 'default';

  constructor() {
    addIcons({ calendar, person });
  }
}
