import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  ModalController,
  PopoverController
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { close, map } from 'ionicons/icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { GeoLocation } from 'viamondo-core/data';
import { GeocodingService, LocationService } from 'viamondo-core/location';

import { FullMapComponent } from '../../../maps/components/full-map/full-map.component';
import { MapService } from '../../../maps/services/map.service';
import { PluralizePipe } from '../../../shared/pipes/pluralize.pipe';

@Component({
  selector: 'app-select-city',
  templateUrl: './select-city.modal.html',
  styleUrls: ['./select-city.modal.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    PluralizePipe,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonText,
    IonSearchbar,
    IonSpinner,
    IonList,
    IonItem,
    IonFabButton,
    IonLabel
  ]
})
export class SelectCityModal {
  searching: boolean;
  results: GeoLocation[] = [];
  searchTerm: string;
  geoLocationError = false;
  private searchSubscription: Subscription;

  @Input()
  allowCurrentLocation = true;

  @Input()
  allowClose = true;

  constructor(
    private modalController: ModalController,
    private popoverCtrl: PopoverController,
    private locationService: LocationService,
    private geocodingService: GeocodingService,
    private spinnerService: NgxSpinnerService,
    private mapService: MapService
  ) {
    addIcons({ close, map });
  }

  onSearchInput(event: any): void {
    this.searchTerm = event.detail.value;
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (!this.searchTerm || this.searchTerm.length < 2) {
      this.results = [];
      this.searching = false;
      return;
    }

    this.searching = true;
    this.geocodingService.search(this.searchTerm).subscribe(results => {
      this.results = results;
      this.searching = false;
    });
  }

  choose(result: GeoLocation): void {
    this.modalController.dismiss(result);
  }

  async openMap(result: GeoLocation, event: any): Promise<void> {
    event.stopPropagation();
    const popover = await this.popoverCtrl.create({
      component: FullMapComponent,
      componentProps: {
        isSinglePointMap: true,
        mapDataCreator: () => this.mapService.getMapForSinglePoint(result),
        text: result.city,
        displayType: 'popover'
      },
      cssClass: 'map-popover',
      reference: 'event',
      event
    });
    return await popover.present();
  }

  getCurrentLocation(): void {
    this.spinnerService.show();
    this.locationService.getLocation(true).subscribe({
      next: result => {
        this.geoLocationError = false;
        this.results = [result];
        this.spinnerService.hide();
      },
      error: () => {
        this.spinnerService.hide();
        this.geoLocationError = true;
      }
    });
  }

  back(): void {
    this.modalController.dismiss();
  }
}
