import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, zip } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

import { CategoriesInfo } from './places';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly VIAMONDO_TOKEN = 'ViaMondoToken';
  private readonly VIAMONDO_CURRENT_USER_ID = 'ViaMondoCurrentUser';
  private readonly VIAMONDO_CATEGORIES = 'ViaMondoCategories';
  private readonly VIAMONDO_CATEGORIES_UPDATED_AT = 'ViaMondoCategoriesUpdatedTime';

  private _storage: Storage | null = null;
  private inMemoryStorage: {
    token?: string;
    userId?: string;
    categories?: CategoriesInfo;
    categoriesUpdatedTime?: Date;
  };

  constructor(
    private storage: Storage,
    private sessionService: SessionService
  ) {
    this.inMemoryStorage = {
      token: undefined,
      userId: undefined,
      categories: undefined,
      categoriesUpdatedTime: undefined
    };
  }

  load(): Observable<void> {
    return from(this.init()).pipe(
      concatMap(() =>
        zip(
          this.get(this.VIAMONDO_TOKEN),
          this.get(this.VIAMONDO_CURRENT_USER_ID),
          this.get(this.VIAMONDO_CATEGORIES),
          this.get(this.VIAMONDO_CATEGORIES_UPDATED_AT)
        )
      ),
      tap(([token, userId, categories, categoriesTime]) => {
        this.inMemoryStorage.token = token;
        this.startSessionIfValid(token);
        this.inMemoryStorage.userId = userId;
        this.inMemoryStorage.categories = categories;
        this.inMemoryStorage.categoriesUpdatedTime = categoriesTime;
      }),
      map(() => undefined)
    );
  }

  clear(): void {
    this.sessionService.removeAuthenticatedSession();
    this.setCurrentUserId(undefined);
    this.setToken(undefined);
  }

  getToken(): string | undefined {
    return this.inMemoryStorage.token;
  }

  setToken(token: string): void {
    this.startSessionIfValid(token);
    this.inMemoryStorage.token = token;
    this.set(this.VIAMONDO_TOKEN, token);
  }

  getCurrentUserId(): string | undefined {
    return this.inMemoryStorage.userId;
  }

  setCurrentUserId(userId: string): void {
    this.inMemoryStorage.userId = userId;
    this.set(this.VIAMONDO_CURRENT_USER_ID, userId);
  }

  getCategories(): CategoriesInfo | undefined {
    return this.inMemoryStorage.categories;
  }

  getCategoriesLastUpdatedTime(): Date | undefined {
    return this.inMemoryStorage.categoriesUpdatedTime;
  }

  setCategories(categories: CategoriesInfo): void {
    this.inMemoryStorage.categories = categories;
    this.inMemoryStorage.categoriesUpdatedTime = new Date();
    this.set(this.VIAMONDO_CATEGORIES, categories);
    this.set(this.VIAMONDO_CATEGORIES_UPDATED_AT, this.inMemoryStorage.categoriesUpdatedTime);
  }

  private async init(): Promise<void> {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  private set(key: string, value: any): void {
    this._storage?.set(key, value);
  }

  private async get(key: string): Promise<any> {
    const valueFromStorage = await this._storage?.get(key);
    return valueFromStorage ?? undefined;
  }

  private startSessionIfValid(token?: string): void {
    if (token) {
      this.sessionService.addAuthenticatedSession();
    }
  }
}
