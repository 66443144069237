import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonCol, IonRouterLinkWithHref, IonRow } from '@ionic/angular/standalone';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink, IonRouterLinkWithHref, IonRow, IonCol]
})
export class FooterComponent {
  get currentYear(): string {
    return new Date().getFullYear().toString();
  }
}
