import { Pipe, PipeTransform } from '@angular/core';
import { GeoLocation } from 'viamondo-core/data';

@Pipe({
  name: 'location',
  standalone: true
})
export class LocationPipe implements PipeTransform {
  withState = ['us', 'ca', 'au', 'cn', 'mx', 'my', 'it'];

  transform(loc: GeoLocation, includeStreet?: boolean): string {
    if (!loc) {
      return '';
    }

    const locArray = [];
    if (includeStreet && loc.street) {
      locArray.push(loc.street);
    }

    if (loc.city) {
      locArray.push(loc.city);
    }

    if (loc.country_code && this.withState.indexOf(loc.country_code) >= 0 && loc.state) {
      locArray.push(loc.state);
    }

    locArray.push(loc.country);
    return locArray.join(', ');
  }
}
