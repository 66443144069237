import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler as AngularErrorHandler, APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicRouteStrategy, provideIonicAngular, ToastController } from '@ionic/angular/standalone';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { register as registerSwiperElementBundle } from 'swiper/element/bundle';
import { AuthorizationInterceptor, BackendService, ErrorInterceptor } from 'viamondo-core/api';
import { CloudinaryService } from 'viamondo-ui/images';

import { AppInitializerService } from './app/app-initializer.service';
import { routes } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { CustomTitleStrategy } from './app/custom-title-strategy';
import { ErrorHandler } from './app/error-handler';
import { environment } from './environments/environment';

registerSwiperElementBundle();

const appInit =
  (appInitializer: AppInitializerService): (() => Promise<void>) =>
  () =>
    appInitializer.loadAll();

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      IonicStorageModule.forRoot(),
      NgxSpinnerModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWhenStable:10000' })
    ),
    provideRouter(routes),
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [AppInitializerService], multi: true },
    { provide: AngularErrorHandler, useClass: ErrorHandler, deps: [ToastController, NgxSpinnerService] },
    BackendService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: CloudinaryService,
      useFactory: () =>
        new CloudinaryService(`${environment.cloudinaryConfig.protocol}//res.cloudinary.com/${environment.cloudinaryConfig.cloud_name}`)
    },
    provideAnimations(),
    provideIonicAngular({})
  ]
}).catch(err => console.log(err));
