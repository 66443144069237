import { ErrorHandler as AngularErrorHandler } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';
import { NgxSpinnerService } from 'ngx-spinner';

export class ErrorHandler implements AngularErrorHandler {
  constructor(
    private toastController: ToastController,
    private spinnerService: NgxSpinnerService
  ) {}

  handleError(error: unknown): void {
    console.error('Unexpected error: ', error);

    let displayableError: string | unknown;
    try {
      displayableError = JSON.stringify(error);
    } catch (e) {
      displayableError = error;
    }

    this.toastController
      .create({
        message: 'Unexpected error: ' + displayableError,
        duration: 60000,
        cssClass: 'break-anywhere',
        buttons: [
          {
            text: 'Close',
            role: 'cancel'
          }
        ]
      })
      .then(toast => {
        toast.present();
        this.spinnerService.hide();
      });
  }
}
