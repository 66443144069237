import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { Image } from 'viamondo-core/data';

import { CloudinaryOptions, CloudinaryService } from '../services/cloudinary.service';

@Directive({
  selector: '[images-src]',
  standalone: true
})
export class ImageDirective implements OnInit, OnDestroy {
  private readonly defaultBackupImg =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkcFX6DwACHAFoPMvLLAAAAABJRU5ErkJggg==';
  private dprSubscription: Subscription;
  private lastDprValue: number | undefined;
  private hasInitRun = false;
  private imgValue: Image | string | undefined;

  @Input('images-src')
  set image(value: Image | string | undefined) {
    this.imgValue = value;
    if (this.hasInitRun) {
      this.updateImage(value);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('images-srcset')
  srcSetValues?: string[];

  @HostListener('load')
  onLoad(): void {
    this.el.nativeElement.classList.add('img-loaded');
  }

  @HostListener('error')
  onError(): void {
    this.el.nativeElement.classList.add('img-error');
  }

  constructor(
    private el: ElementRef,
    private cloudinaryService: CloudinaryService
  ) {}

  ngOnInit(): void {
    this.updateImage(this.imgValue);
    this.hasInitRun = true;
  }

  ngOnDestroy(): void {
    if (this.dprSubscription) {
      this.dprSubscription.unsubscribe();
    }
  }

  private updateImage(value: Image | string | undefined): void {
    if (!value) {
      this.src = this.defaultBackupImg;
      this.srcSet = undefined;
    } else if (typeof value === 'string') {
      this.src = value;
      this.srcSet = undefined;
    } else if (value.local) {
      this.src = value.webPath;
      this.srcSet = undefined;
    } else if (value.publicId) {
      this.handleCloudinaryImage(value);
    } else if (value.url) {
      this.src = value.url;
      this.srcSet = undefined;
    } else {
      this.src = this.defaultBackupImg;
      this.srcSet = undefined;
    }
  }

  private handleCloudinaryImage(image: Image): void {
    let width: number = +this.el.nativeElement.getAttribute('width');
    let height: number = +this.el.nativeElement.getAttribute('height');

    // Use defined width & height
    if (width && height) {
      this.setImageSrcAndSrcset(image, width, height);
      return;
    }

    const rect = this.el.nativeElement.getBoundingClientRect();
    width = width || rect.width;
    height = height || rect.height;

    // Use defined srcset values
    if (this.srcSetValues) {
      this.dprSubscription = this.cloudinaryService
        .dpr()
        .pipe(
          tap(dpr => {
            if (!this.lastDprValue || dpr > this.lastDprValue) {
              this.srcSet = this.srcSetValues
                .map(imageWidth => {
                  const widthInPx = +imageWidth.match(/\d+/)[0];
                  return `${this.getCloudinaryUrl(image, widthInPx, +height * dpr)} ${imageWidth}`;
                })
                .join(', ');
              this.src = this.getCloudinaryUrl(image, width * dpr, height * dpr);
              this.lastDprValue = dpr;
            }
          })
        )
        .subscribe();
      return;
    }

    console.warn(`Possibly missing image width (${width}) & height (${height})`, this.el.nativeElement);
    this.setImageSrcAndSrcset(image, width, height);
  }

  private setImageSrcAndSrcset(image: Image, width: number, height: number): void {
    this.src = this.getCloudinaryUrl(image, width, height);
    this.srcSet = [1, 1.5, 2, 2.5, 3, 3.5, 4]
      .map(ratio => {
        const actualWidth = width * ratio;
        return `${this.getCloudinaryUrl(image, actualWidth, height * ratio)} ${ratio}x`;
      })
      .join(', ');
  }

  private set src(value: string) {
    this.el.nativeElement.setAttribute('src', value);
  }

  private set srcSet(value: string | undefined) {
    if (value) {
      this.el.nativeElement.setAttribute('srcset', value);
    } else {
      this.el.nativeElement.removeAttribute('srcset');
    }
  }

  private getCloudinaryUrl(image: Image, width?: number, height?: number): string {
    const quality: 'eco' | 'low' | 'good' = this.el.nativeElement.getAttribute('data-quality') ?? 'good';
    const gravity: 'center' | 'none' | 'face:center' | 'auto' = this.el.nativeElement.getAttribute('data-gravity') ?? 'auto';

    const options: CloudinaryOptions = {
      type: image.type,
      version: image.version,
      crop: this.el.nativeElement.getAttribute('data-crop') ?? 'fill',
      width: width ? Math.ceil(width) : undefined,
      height: height ? Math.ceil(height) : undefined,
      gravity: gravity === 'none' ? undefined : gravity,
      quality: `auto:${quality}`,
      effect: this.el.nativeElement.getAttribute('data-transformation') === 'background' ? 'blur:500' : undefined,
      opacity: this.el.nativeElement.getAttribute('data-transformation') === 'background' ? 75 : undefined,
      format: 'auto'
    };
    return this.cloudinaryService.url(image.publicId, options);
  }
}
