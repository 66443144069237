import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonTitle,
  IonToolbar,
  ModalController
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { close, personAdd } from 'ionicons/icons';
import { Notification, NotificationsService, SocialUser } from 'viamondo-core/data';
import { isIonRefresherEvent } from 'viamondo-utils/ionic-type-guards';

import { TimeagoPipe } from '../../../shared/pipes/timeago.pipe';
import { ProfilePicComponent } from '../../components/profile-pic/profile-pic.component';
import { SelectGroupDirective } from '../../directives/select-group.directive';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications.modal.html',
  styleUrls: ['./notifications.modal.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    ProfilePicComponent,
    SelectGroupDirective,
    TimeagoPipe,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonSpinner,
    IonList,
    IonItem,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  ]
})
export class NotificationsModal implements OnInit {
  @Input()
  displayType: 'modal' | 'popover' = 'modal';

  loaded: boolean;
  isOnline = true; // FIXME
  notifications: Notification[];

  constructor(
    private modalController: ModalController,
    private router: Router,
    private notificationsService: NotificationsService
  ) {
    addIcons({ close, personAdd });
  }

  ngOnInit(): void {
    this.notificationsService.getNotifications().subscribe(notifications => {
      this.notifications = notifications;
      this.loaded = true;
    });
  }

  hide(): void {
    this.dismiss();
  }

  viewProfile(user: SocialUser): Promise<boolean> {
    this.dismiss();
    return this.router.navigateByUrl(`/users/${user._id}`);
  }

  viewTarget(notification: Notification): Promise<boolean> {
    this.dismiss();
    if (notification.target.basicType === 'Trip') {
      return this.router.navigateByUrl(`/trips/${notification.target.id}`);
    } else if (notification.target.basicType === 'Place') {
      return this.router.navigateByUrl(`/places/${notification.target.id}`);
    } else {
      throw new Error(`Cannot view target if the type is not "Trip" or "Place". Received: ${notification.target.basicType}`);
    }
  }

  doRefresh(event: Event): void {
    if (!isIonRefresherEvent(event)) {
      throw new Error(`doRefresh did not receive a refresh event: ${event}`);
    }

    this.notificationsService.getNotifications().subscribe({
      next: notifications => {
        this.notifications = notifications;
        event.detail.complete();
      },
      error: error => {
        event.detail.complete();
        throw error;
      }
    });
  }

  loadMore(event: Event): void {
    const infiniteScroll = event.target as unknown as IonInfiniteScroll;
    this.notificationsService.getNotifications(false).subscribe({
      next: notifications => {
        this.notifications = this.notifications.concat(notifications);
        infiniteScroll.complete();
      },
      error: error => {
        infiniteScroll.complete();
        throw error;
      }
    });
  }

  private dismiss(): void {
    if (this.displayType === 'modal') {
      this.modalController.dismiss();
    }
  }
}
