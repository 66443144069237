import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonIcon, IonSkeletonText } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { calendar, person, personAdd } from 'ionicons/icons';
import { MinimalPlace } from 'viamondo-core/data';

import { ImageDirective } from '../../../images/directives/image.directive';
import { LocationPipe } from '../../../shared/pipes/location.pipe';
import { CategoryBubbleComponent } from '../category-bubble/category-bubble.component';
import { RatingComponent } from '../rating/rating.component';

@Component({
  selector: 'places-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgClass,
    CategoryBubbleComponent,
    RatingComponent,
    ImageDirective,
    DatePipe,
    LocationPipe,
    IonIcon,
    IonSkeletonText
  ]
})
export class ItemComponent {
  @Input()
  place: MinimalPlace;

  @Input()
  usage = 'default';

  isOnline = true;

  constructor() {
    addIcons({ calendar, person, personAdd });
  }
}
