import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
  PopoverController
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';

import { MulitMapCreator, MultiMapConfig } from '../../models/multi-map-config';
import { SingleMapConfig, SingleMapCreator } from '../../models/single-map-config';
import { MultiPointMapComponent } from '../multi-point-map/multi-point-map.component';
import { SinglePointMapComponent } from '../single-point-map/single-point-map.component';

@Component({
  selector: 'app-full-map',
  templateUrl: './full-map.component.html',
  styleUrls: ['./full-map.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MultiPointMapComponent,
    SinglePointMapComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent
  ]
})
export class FullMapComponent {
  @Input()
  isSinglePointMap = false;

  @Input()
  mapDataCreator: () => MultiMapConfig | SingleMapConfig;

  @Input()
  text: string;

  @Input()
  displayType: 'modal' | 'popover' = 'modal';

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController
  ) {
    addIcons({ close });
  }

  get multiMapCreator(): MulitMapCreator {
    if (this.isSinglePointMap) {
      throw new Error('Mixing single and multi point maps!');
    }
    return this.mapDataCreator as MulitMapCreator;
  }

  get singleMapCreator(): SingleMapCreator {
    if (!this.isSinglePointMap) {
      throw new Error('Mixing single and multi point maps!');
    }
    return this.mapDataCreator as SingleMapCreator;
  }

  close(): void {
    if (this.displayType === 'modal') {
      this.modalController.dismiss();
    } else {
      this.popoverController.dismiss();
    }
  }
}
