import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { IonCheckbox } from '@ionic/angular/standalone';

import { MultiMapConfig } from '../../models/multi-map-config';
import { MapService } from '../../services/map.service';

let counter = 0;

@Component({
  selector: 'app-multi-point-map',
  templateUrl: './multi-point-map.component.html',
  styleUrls: ['./multi-point-map.component.scss'],
  standalone: true,
  imports: [NgIf, LeafletModule, LeafletMarkerClusterModule, NgFor, IonCheckbox]
})
export class MultiPointMapComponent {
  private map: L.Map;
  id = `multi-point-map-${counter++}`;
  clusteredCheckbox = true;
  clustered = true;
  mapConfig?: MultiMapConfig;

  @Input()
  set mapDataCreator(func: () => MultiMapConfig | undefined) {
    if (func) {
      this.mapConfig = func();
    }
  }

  constructor(private mapService: MapService) {}

  onMapReady(map: L.Map): void {
    this.map = map;
    setTimeout(() => {
      // Work-around to fix grey map
      this.mapService.getTileLayer().addTo(map);
      this.redrawMap();
    }, 500);
  }

  toggleClustered(): void {
    this.clusteredCheckbox = !this.clusteredCheckbox;
    this.redrawMap();
    setTimeout(() => (this.clustered = !this.clustered), 500);
  }

  private redrawMap(): void {
    if (!this.map) {
      return;
    }

    this.map.invalidateSize();
    if (this.mapConfig.bounds.isValid()) {
      this.map.fitBounds(this.mapConfig.bounds, this.mapConfig.boundsOptions);
    }
  }
}
