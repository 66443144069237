import { Routes } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';
import { NoSessionTokenGuard } from './session/guards/no-session-token.guard';
import { SessionTokenGuard } from './session/guards/session-token.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'session',
    canActivate: [NoSessionTokenGuard],
    loadChildren: () => import('./session/session.module').then(m => m.sessionRoutes)
  },
  {
    path: 'tabs',
    canActivate: [SessionTokenGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.tabsRoutes)
  },
  {
    path: 'check-in', // old link from previous web app
    redirectTo: 'places'
  },
  {
    path: 'places',
    loadChildren: () => import('./places/places.module').then(m => m.placesRoutes)
  },
  {
    path: 'place', // old link from previous web app
    redirectTo: 'venues'
  },
  {
    path: 'venues',
    loadChildren: () => import('./venues/venues.module').then(m => m.venuesRoutes)
  },
  {
    path: 'trip', // old link from previous web app
    redirectTo: 'trips'
  },
  {
    path: 'trips',
    loadChildren: () => import('./trips/trips.module').then(m => m.tripsRoutes)
  },
  {
    path: 'users',
    canActivate: [SessionTokenGuard],
    loadChildren: () => import('./users/users.module').then(m => m.usersRoutes)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.settingsRoutes)
  },
  {
    path: '**',
    title: 'Not Found',
    component: NotFoundComponent
  }
];
