import { NgIf, SlicePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';
import { MinimalUser } from 'viamondo-core/data';

import { ImageDirective } from '../../../images/directives/image.directive';

@Component({
  selector: 'users-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss'],
  standalone: true,
  imports: [NgIf, ImageDirective, SlicePipe, IonIcon]
})
export class ProfilePicComponent {
  imageError = false;

  @Input()
  size: number;

  @Input()
  user: MinimalUser;

  onLoadError(): void {
    this.imageError = true;
  }
}
