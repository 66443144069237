import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { FooterComponent, HeaderComponent } from 'viamondo-ui';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, NgxSpinnerComponent, IonApp, IonRouterOutlet]
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.initializeApp();
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      this.checkNavigationEvents();
    });
  }

  private checkNavigationEvents(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.spinner.show();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.spinner.hide();
      }
    });
  }
}
