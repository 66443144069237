import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router
  ) {}

  update(description: string, img?: string): void {
    const fullTitle = this.title.getTitle();
    const fullUrl = `${environment.shareUrl}${this.router.url}`;
    const fullImg = img ? img : `${environment.shareUrl}/assets/img/promo.png`;

    // Set HTML description
    this.meta.updateTag({ name: 'description', content: description });

    // Update OpenGraph Tags
    this.meta.updateTag({ property: 'og:title', content: fullTitle });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:url', content: fullUrl });
    this.meta.updateTag({ property: 'og:image', content: fullImg });

    // Update Twitter Tags
    this.meta.updateTag({ name: 'twitter:title', content: fullTitle });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: fullImg });
  }
}
