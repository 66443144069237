import { Directive, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { NgxSpinnerService } from 'ngx-spinner';
import { Image } from 'viamondo-core/data';

import { ImageModal } from '../modals/image/image.modal';

@Directive({
  selector: '[images-show]',
  standalone: true
})
export class ShowImagesDirective {
  @Input('images-show')
  images: Image | Image[];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('start-image')
  index?: number;

  constructor(
    private modalController: ModalController,
    private spinnerService: NgxSpinnerService
  ) {}

  @HostListener('click', ['$event'])
  async onClick(event: Event): Promise<void> {
    event.stopPropagation();
    this.spinnerService.show();
    let imageArray: Image[];
    if (this.isArray(this.images)) {
      imageArray = this.images;
    } else {
      imageArray = [this.images];
    }

    const filteredImages = (imageArray || []).filter(img => img.publicId);
    if (filteredImages.length > 0) {
      const imageModal = await this.modalController.create({
        component: ImageModal,
        componentProps: {
          index: this.index || 0,
          images: filteredImages
        }
      });
      await imageModal.present();
      this.spinnerService.hide();
    }
  }

  private isArray(value: Image | Image[]): value is Image[] {
    return (value as any).length !== undefined;
  }
}
