import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
  standalone: true
})
export class PluralizePipe implements PipeTransform {
  transform(count: number, zeroString: string, oneString: string, pluralString: string): string {
    if (count === 0) {
      return this.addNumber(count, zeroString);
    } else if (count === 1) {
      return this.addNumber(count, oneString);
    } else {
      return this.addNumber(count, pluralString);
    }
  }

  private addNumber(count: number, valueString: string): string {
    return valueString.replace('{}', count.toString());
  }
}
