<ng-container *ngIf="user">
  <img
    *ngIf="user.profileImage && !imageError"
    [images-src]="user.profileImage"
    data-gravity="face:center"
    data-quality="eco"
    class="photo-avatar-{{ size }}"
    [height]="size"
    [width]="size"
    loading="lazy"
    (error)="onLoadError()"
    alt="{{ user.firstName }} {{ user.lastName }}'s profile picture"
  />
  <div *ngIf="!user.profileImage || imageError" class="avatar-circle-{{ size }}">
    <span class="initials">{{ user.firstName | slice: 0 : 1 }}{{ user.lastName | slice: 0 : 1 }}</span>
  </div>
</ng-container>
<div *ngIf="!user" class="skeleton avatar-circle-{{ size }}">
  <ion-icon src="/assets/fontawesome/solid/question.svg"></ion-icon>
</div>
