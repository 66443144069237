import { Directive, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { NgxSpinnerService } from 'ngx-spinner';

import { FullMapComponent } from '../components/full-map/full-map.component';
import { MultiMapConfig } from '../models/multi-map-config';
import { SingleMapConfig } from '../models/single-map-config';

@Directive({
  selector: '[maps-open]',
  standalone: true
})
export class OpenMapDirective {
  @Input('maps-open')
  mapCreator?: () => MultiMapConfig | SingleMapConfig;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('maps-single-point')
  isSinglePointMap?: boolean;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('map-text')
  mapText?: string;

  constructor(
    private modalController: ModalController,
    private spinner: NgxSpinnerService
  ) {}

  @HostListener('click')
  async onClick(): Promise<void> {
    this.spinner.show();
    const modal = await this.modalController.create({
      component: FullMapComponent,
      componentProps: {
        isSinglePointMap: this.isSinglePointMap,
        mapDataCreator: this.mapCreator,
        text: this.mapText
      }
    });
    await modal.present();
    this.spinner.hide();
  }
}
